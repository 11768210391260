import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useAuthContext } from '../../context/authContext';

export default function PublicRoute() {
  const { token } = useAuthContext();
  let location = useLocation();
  if (token && location.pathname === '/login') return <Navigate to={'/dashboard'}></Navigate>;
  return <Outlet />;
}
