import packageJson from '../../../package.json';
import CustomLocalStorage from './localStorage';
export default async () => {
  let version = CustomLocalStorage.getItem('version');
  if (version != packageJson.version) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      window.location.reload(true);
    }

    // localforage.clear();
    CustomLocalStorage.setItem('version', packageJson.version);
  }
};
