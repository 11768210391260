import Menu from 'components/application/home/Menu';
import NavBar from 'components/application/home/NavBar';
import ErrorBoundary from 'components/ErrorBoundary';
import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { Outlet } from 'react-router';
import cache from 'utils/cache';
import { AuthContext } from '../context/authContext';
import { useStoreSidebar, useStoreSavingLoading } from '../store/catalogs.store';

const App = (props) => {
  const getStoreSidebar = useStoreSidebar();
  const { saving } = useStoreSavingLoading();
  const context = useContext(AuthContext);

  useEffect(() => {
    cache();
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    collapsedMenu();
  }, [getStoreSidebar.collapsed]);

  const updateWindowDimensions = () => {
    if (window.innerWidth > 990) {
      if (!getStoreSidebar.collapsed) {
        document.body.classList.remove('sidebar-collapse');
      } else {
        document.body.classList.remove('sidebar-closed');
        document.body.classList.remove('sidebar-open');
      }
    } else {
      if (getStoreSidebar.sidebar) {
        document.body.classList.add('sidebar-collapse');
      }
      document.body.classList.add('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    }
  };

  const collapsedMenu = () => {
    if (document.body.classList.contains('sidebar-collapse')) {
      document.body.classList.remove('sidebar-collapse');
    } else {
      document.body.classList.add('sidebar-collapse');
    }
    if (document.body.classList.contains('sidebar-closed')) {
      document.body.classList.remove('sidebar-closed');
      document.body.classList.add('sidebar-open');
    } else if (document.body.classList.contains('sidebar-open')) {
      document.body.classList.remove('sidebar-open');
      document.body.classList.add('sidebar-closed');
    }
  };

  return (
    <ErrorBoundary {...props}>
      <div className='main-header'>
        <NavBar
          {...props}
          loaded={saving}
          loggedIn={context.token}
          ambiente={context.user && context.user.ambiente}
          userId={context.user && context.user.id}
        />
      </div>
      <Menu {...props} />
      <Outlet />
      <footer className='main-footer' style={!context.token ? { marginLeft: '0px' } : {}}>
        <div className='pull-right hidden-xs'>
          <b>Version</b> 3.0.0
        </div>
        <strong>
          Copyright © 2024{' '}
          <a href='https://codigoblanco.mx' className='link-footer'>
            Código Blanco
          </a>
          .
        </strong>{' '}
        Todos los derechos reservados.
      </footer>
    </ErrorBoundary>
  );
};

App.propTypes = {
  children: PropTypes.node,
  router: PropTypes.object,
  location: PropTypes.object,
};

export default App;
