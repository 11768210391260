import { useAuthContext } from 'context/authContext';
import IfAccess from 'controls/IfAccess';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'stateless';
import 'styles/nav-bar.css';
import { urlPhoto } from 'utils/api';
import { printDiffDate } from 'utils/parseDates';
import { isURL } from 'validator';
import { useStoreSidebar } from '../../../store/catalogs.store';

export default function NavBar() {
  const getStoreSidebar = useStoreSidebar();
  const { user, token, notifications, messages, tasks, headerTitle } = useAuthContext();
  const [openTasks, setOpenTasks] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    getStoreSidebar.toggleCollapsed();
  };

  const getSrcImg = (avatar) => {
    if (isURL(avatar + '')) return avatar;
    if (avatar) return urlPhoto() + avatar;
    return 'https://img2.freepng.es/20180612/ih/kisspng-computer-icons-avatar-user-profile-clip-art-5b1f69f0e68650.4078880515287853929442.jpg';
  };

  return (
    <>
      <Link className='logo navbar-brand hidden-xs' to='/dashboard'>
        <img src='/img/logo2.png' className='logo-lg' />
        <img src='/img/favicon.png' className='logo-mini' />
      </Link>
      <nav className='navbar navbar-default navbar-static-top' style={{ padding: 0 }}>
        <div className='container-fluid' style={{ display: 'block' }}>
          {token && (
            <>
              <a onClick={onClick} href='#' className='sidebar-toggle' data-toggle='push-menu' role='button'>
                <span className='sr-only'>Toggle navigation</span>
              </a>
              <a onClick={(e) => e.preventDefault()} href='#' className='sidebar-title hidden-xs'>
                {headerTitle}
              </a>
              <div className='navbar-custom-menu'>
                <ul className='nav navbar-nav' style={{ display: 'block' }}>
                  <IfAccess path='mensajes'>
                    <li className={`dropdown messages-menu ${openMessages ? 'open' : ''}`}>
                      <a
                        href='#'
                        className='dropdown-toggle'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenNotifications(false);
                          setOpenMessages(!openMessages);
                          setOpenTasks(false);
                        }}
                      >
                        <i className='glyphicon glyphicon-envelope'></i>
                        {messages?.length > 0 ? <span className='label label-success'>{messages?.length}</span> : null}
                      </a>
                      <ul className='dropdown-menu'>
                        <li className='header'>Tienes {messages?.length} mensajes</li>
                        <li>
                          <ul className='menu'>
                            {messages &&
                              messages?.map((task, i) => (
                                <li key={i}>
                                  <a href='#'>
                                    <div className='pull-left'>
                                      <img
                                        src={getSrcImg(task.emisor.avatar)}
                                        className='img-circle'
                                        alt={task.emisor.email}
                                        title={task.emisor.email}
                                      />
                                    </div>
                                    <h4>
                                      {task.emisor.name}
                                      <small>
                                        <i className='glyphicon glyphicon-check'></i> {printDiffDate(task.created)}
                                      </small>
                                    </h4>
                                    <p>{task.asunto}</p>
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li className='footer'>
                          <Link to='/mensajes' onClick={() => {}}>
                            Mirar todos los mensajes
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </IfAccess>
                  {notifications?.length > 0 && (
                    <li className={`dropdown notifications-menu ${openNotifications ? 'open' : ''}`}>
                      <a
                        href='#'
                        className='dropdown-toggle'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenNotifications(!openNotifications);
                          setOpenMessages(false);
                          setOpenTasks(false);
                        }}
                      >
                        <i className='glyphicon glyphicon-bell'></i>
                        {notifications?.length > 0 ? <span className='label label-warning'>{notifications?.length}</span> : null}
                      </a>
                      <ul className='dropdown-menu'>
                        <li className='header'>Tienes {notifications?.length} notificaciones</li>
                        <li>
                          <ul className='menu'>
                            {notifications.map((notification) => (
                              <li key={notification._id}>
                                <Link to={notification.url}>
                                  <i className='glyphicon glyphicon-check text-aqua'></i> <b>{notification.asunto}</b>
                                  <small className='pull-right'>{printDiffDate(notification.created)}</small>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <IfAccess path='notificaciones'>
                          <li className='footer'>
                            <Link to='/notificaciones' onClick={() => {}}>
                              Mirar todas{' '}
                            </Link>
                          </li>
                        </IfAccess>
                      </ul>
                    </li>
                  )}
                  <IfAccess path='tareas'>
                    <li className={`dropdown tasks-menu ${openTasks ? 'open' : ''}`}>
                      <a
                        href='#'
                        className='dropdown-toggle'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenNotifications(false);
                          setOpenMessages(false);
                          setOpenTasks(!openTasks);
                        }}
                      >
                        <i className='glyphicon glyphicon-flag'></i>
                        {tasks?.length > 0 ? <span className='label label-danger'>{tasks?.length}</span> : null}
                      </a>
                      <ul className='dropdown-menu'>
                        <li className='header'>You have {tasks?.length} tasks</li>
                        <li>
                          <ul className='menu'>
                            <li>
                              <a href='#'>
                                <h3>
                                  Design some buttons
                                  <small className='pull-right'>20%</small>
                                </h3>
                                <div className='progress xs'>
                                  <div
                                    className='progress-bar progress-bar-aqua'
                                    style={{ width: '20%' }}
                                    role='progressbar'
                                    aria-valuenow='20'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                  >
                                    <span className='sr-only'>20% Complete</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className='footer'>
                          <Link to='/tareas' onClick={() => {}}>
                            Mirar todos las tareas
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </IfAccess>
                  <li className='dropdown user user-menu'>
                    <a href='#'>
                      <img src={getSrcImg(user && user.avatar)} className='user-image' alt={user && user.email} title={user && user.email} />
                      <span className='hidden-xs'> {user && user.name}</span>
                    </a>
                  </li>
                  <li className='dropdown user user-menu'>
                    <Link to='/logout'>
                      <Icon icon='log-out' />
                      <span className='hidden-xs'> Cerrar sesión</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
}
