import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { requireAuth } from './utils/router';
import App from './containers/App';
// import Dashboard from 'containers/Dashboard';
import PublicRoute from './pages/router/PublicRoute';
import PrivateRoute from './pages/router/PrivateRoute';
import { useAuthContext } from 'context/authContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStoreTipoCambio } from './store/catalogs.store';
import { Loading } from 'stateless';

const LazyLoad = (props, Component) => {
  const { openAlert, notify, user, agente, wsSend } = useAuthContext();
  const getStoreTipoCambio = useStoreTipoCambio();
  let location1 = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  return (
    <Suspense fallback={<Loading />}>
      <Component
        alert={openAlert}
        notification={openAlert}
        notify={notify}
        navigate={navigate}
        location={location1}
        params={params}
        wsSend={wsSend}
        user={user}
        tipoCambio={getStoreTipoCambio.tipoCambio}
        agente={agente}
        {...props}
      />
    </Suspense>
  );
};

const Dashboard = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('containers/Dashboard').catch(() => window.location.reload()))
  );
const FirmarCotizacion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones/cliente/FirmarPublica').catch(() => window.location.reload()))
  );
const FirmarContratoEquipo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/contrato-publico/FirmarPublica.js').catch(() => window.location.reload()))
  );
const Login = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/session/Login').catch(() => window.location.reload()))
  );
const Modules = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/modules').catch(() => window.location.reload()))
  );
const Users = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/users').catch(() => window.location.reload()))
  );
const ApplicationData = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/logs/ApplicationData').catch(() => window.location.reload()))
  );
const Files = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/files').catch(() => window.location.reload()))
  );

const Logout = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/session/Logout').catch(() => window.location.reload()))
  );

const PageNotFound = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/security/PageNotFound').catch(() => window.location.reload()))
  );
const Forbidden = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/security/Forbidden').catch(() => window.location.reload()))
  );

const StoreEquipments = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/store-equipments').catch(() => window.location.reload()))
  );
const StoreJhonnyCars = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/store-jhonnycars').catch(() => window.location.reload()))
  );

const RecepcionCompras = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/recepciones-compras').catch(() => window.location.reload()))
  );
const NuevaRecepcion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/recepciones-compras/add').catch(() => window.location.reload()))
  );
const EditarRecepcion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/recepciones-compras/edit').catch(() => window.location.reload()))
  );
const DetalleRecepcion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/recepciones-compras/detail').catch(() => window.location.reload()))
  );

const SurtidoCarros = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/surtido-carros/').catch(() => window.location.reload()))
  );
const SurtirCarro = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/surtido-carros/add').catch(() => window.location.reload()))
  );

const Traspasos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/traspasos').catch(() => window.location.reload()))
  );
const NuevoTraspaso = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/traspasos/New').catch(() => window.location.reload()))
  );
const DetalleTraspaso = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/traspasos/detail').catch(() => window.location.reload()))
  );

const Mermas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/mermas').catch(() => window.location.reload()))
  );
const NuevoMerma = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/mermas/New').catch(() => window.location.reload()))
  );
const DetalleMerma = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/mermas/detail').catch(() => window.location.reload()))
  );

const Ajustes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/ajustes').catch(() => window.location.reload()))
  );
const NuevoAjuste = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/ajustes/New').catch(() => window.location.reload()))
  );
const DetalleAjuste = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/ajustes/detail').catch(() => window.location.reload()))
  );

const Pedidos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/pedidos').catch(() => window.location.reload()))
  );
const NuevoPedido = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/pedidos/New').catch(() => window.location.reload()))
  );
const DetallePedido = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/pedidos/detail').catch(() => window.location.reload()))
  );
const EditarPedido = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/pedidos/edit').catch(() => window.location.reload()))
  );

const Conversiones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/conversiones').catch(() => window.location.reload()))
  );
const NuevoConversion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/conversiones/New').catch(() => window.location.reload()))
  );
const DetalleConversion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/conversiones/detail').catch(() => window.location.reload()))
  );
const EditarConversion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/conversiones/edit').catch(() => window.location.reload()))
  );

const Transations = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/transations/transations').catch(() => window.location.reload()))
  );
const References = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/transations/references').catch(() => window.location.reload()))
  );

// Clientes

const ListCustomerRegistros = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers_registros').catch(() => window.location.reload()))
  );
const AddCustomer = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/add').catch(() => window.location.reload()))
  );
const EditCustomer = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/edit').catch(() => window.location.reload()))
  );
const ListCustomer = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/list').catch(() => window.location.reload()))
  );
const DetailCustomer = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/detail').catch(() => window.location.reload()))
  );
const ContractByCustomer = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/contract').catch(() => window.location.reload()))
  );
const EditarContrato = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/contract/Edit').catch(() => window.location.reload()))
  );
const QuotationByCustomer = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/quotation').catch(() => window.location.reload()))
  );
const Ubicaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/ubicaciones').catch(() => window.location.reload()))
  );
const ImportClientes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/import').catch(() => window.location.reload()))
  );
const UnificarClientes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/unificar').catch(() => window.location.reload()))
  );
const Domicilios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/domicilios').catch(() => window.location.reload()))
  );
const Contactos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/contactos').catch(() => window.location.reload()))
  );
const ContactosClientes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/contactos/ClientesContacto').catch(() => window.location.reload()))
  );

const RuletaAsesores = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/ruleta-asesores').catch(() => window.location.reload()))
  );

const SeguimientoProspectos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/seguimientos/SeguimientoProspectos').catch(() => window.location.reload()))
  );
const OrdenesServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio').catch(() => window.location.reload()))
  );
const OrdenesServicioVisitas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicios-visitas').catch(() => window.location.reload()))
  );
const NuevoOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/New').catch(() => window.location.reload()))
  );
const EditarOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/Edit').catch(() => window.location.reload()))
  );
const VisitasOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/visitas/index').catch(() => window.location.reload()))
  );

const ObservacionesOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/observaciones/observaciones').catch(() => window.location.reload()))
  );
const ObservacionesConclusionesOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/observaciones/conclusiones').catch(() => window.location.reload()))
  );
const ObservacionesComentariosOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/observaciones/comentarios').catch(() => window.location.reload()))
  );
const CalificacionServicioOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/calificacion_servicio/index').catch(() => window.location.reload()))
  );
const FirmaClienteServicioOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/firmas/cliente').catch(() => window.location.reload()))
  );
const FirmaAgenteOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/firmas/agente').catch(() => window.location.reload()))
  );

const DetalleOrdenServicio = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/ordenes-servicio/detail').catch(() => window.location.reload()))
  );

const Existencias = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/store/existencias').catch(() => window.location.reload()))
  );

const AddAgendaMonitoreo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/agenda-monitoreo/add/New').catch(() => window.location.reload()))
  );
const UptAgendaMonitoreo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/agenda-monitoreo/upt/Edit').catch(() => window.location.reload()))
  );
const ListAgendaMonitoreo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/agenda-monitoreo').catch(() => window.location.reload()))
  );
const ImportarAgendaMonitoreo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/agenda-monitoreo/Importar').catch(() => window.location.reload()))
  );

const ClientesSaldos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/customers/customers-saldos').catch(() => window.location.reload()))
  );

// Catalogos

const ListasPrecios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/listas-precios').catch(() => window.location.reload()))
  );
const UnitMeasures = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/unit-measures').catch(() => window.location.reload()))
  );
const Products = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/products').catch(() => window.location.reload()))
  );
const NewProduct = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/products/NewProduct').catch(() => window.location.reload()))
  );

const Configurations = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/configuration').catch(() => window.location.reload()))
  );
const ConfigurationDetail = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/configuration/ConfigurationDetail').catch(() => window.location.reload()))
  );

const Characteristics = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/characteristics').catch(() => window.location.reload()))
  );
const NewCharacteristics = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/characteristics/NewCharacteristics').catch(() => window.location.reload()))
  );
const DetailCharacteristics = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/characteristics/detail/DetailCharacteristics').catch(() => window.location.reload()))
  );
const EditCharacteristics = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/characteristics/EditCharacteristics').catch(() => window.location.reload()))
  );

const PreciosEquipos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/precios-equipos').catch(() => window.location.reload()))
  );

const Equipments = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments').catch(() => window.location.reload()))
  );
const NewEquipment = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments/NewEquipment').catch(() => window.location.reload()))
  );
const ImportEquipment = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments/import').catch(() => window.location.reload()))
  );
const DetailEquipment = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments/detail/DetailEquipment').catch(() => window.location.reload()))
  );
const EditEquipments = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments/EditEquipments').catch(() => window.location.reload()))
  );
const EditPreciosEquipments = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments/EditPreciosEquipments').catch(() => window.location.reload()))
  );
const RendimientosEquipment = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments/rendimientos').catch(() => window.location.reload()))
  );

const Services = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/services/').catch(() => window.location.reload()))
  );
const NewService = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/services/NewService').catch(() => window.location.reload()))
  );
const DetailService = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/services/detail/DetailService').catch(() => window.location.reload()))
  );
const EditServices = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/services/EditServices').catch(() => window.location.reload()))
  );
const ImportServices = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/services/import').catch(() => window.location.reload()))
  );

const Sellers = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/sellers').catch(() => window.location.reload()))
  );

const StoreHouse = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/storehouse').catch(() => window.location.reload()))
  );
const Sucursales = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/sucursales').catch(() => window.location.reload()))
  );
const Providers = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/providers').catch(() => window.location.reload()))
  );

const EquiposSeguridadElectronica = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipos-seguridad-electronica').catch(() => window.location.reload()))
  );
const TipoEstablecimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipo-establecimientos').catch(() => window.location.reload()))
  );
const Rubros = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/rubros').catch(() => window.location.reload()))
  );
const TipoCableados = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipo-cableados').catch(() => window.location.reload()))
  );
const TipoServicios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipo-servicios').catch(() => window.location.reload()))
  );

const FormaPago = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/formas-pago').catch(() => window.location.reload()))
  );
const CondicionesPagoEquipos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/condiciones-equipos').catch(() => window.location.reload()))
  );
const CondicionesPagoServicios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/condiciones-servicios').catch(() => window.location.reload()))
  );

const EsquemasPago = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/esquemas-pago').catch(() => window.location.reload()))
  );
const Plazos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/plazos').catch(() => window.location.reload()))
  );
const Anticipos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/anticipos').catch(() => window.location.reload()))
  );

const TiposContactos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-contactos').catch(() => window.location.reload()))
  );
const Agentes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/agentes').catch(() => window.location.reload()))
  );
const AgentesClientes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/agentes/AgentesClientes').catch(() => window.location.reload()))
  );
const AgentesProspectos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/agentes/AgentesProspectos').catch(() => window.location.reload()))
  );
const Titulos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/titulos').catch(() => window.location.reload()))
  );

const ClasificadoresCotizaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/clasificadores-cotizaciones').catch(() => window.location.reload()))
  );
const ClasificadoresCotizacionesImportar = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/clasificadores-cotizaciones/import').catch(() => window.location.reload()))
  );
const ClasificadoresLevantamientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/clasificadores-levantamientos').catch(() => window.location.reload()))
  );

const Kits = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits').catch(() => window.location.reload()))
  );
const NewKits = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits/NewKits').catch(() => window.location.reload()))
  );
const EditKits = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits/EditKits').catch(() => window.location.reload()))
  );
const ImportKits = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits/import').catch(() => window.location.reload()))
  );

const KitsComerciales = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits-comerciales').catch(() => window.location.reload()))
  );
const NewKitsComerciales = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits-comerciales/NewKits').catch(() => window.location.reload()))
  );
const EditKitsComerciales = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits-comerciales/EditKits').catch(() => window.location.reload()))
  );
const ImportKitsComerciales = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/kits-comerciales/import').catch(() => window.location.reload()))
  );

const Recetas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/recetas').catch(() => window.location.reload()))
  );
const NewRecetas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/recetas/New').catch(() => window.location.reload()))
  );
const EditRecetas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/recetas/Edit').catch(() => window.location.reload()))
  );

const Exceptions = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/exceptions').catch(() => window.location.reload()))
  );
const TipoCambios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipo-cambios').catch(() => window.location.reload()))
  );
const Bancos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/bancos').catch(() => window.location.reload()))
  );
const CuentasBancarias = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/cuentas-bancarias').catch(() => window.location.reload()))
  );
const Motivos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/motivos').catch(() => window.location.reload()))
  );
const ResultadosVisitas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/resultados-visitas').catch(() => window.location.reload()))
  );
const Documentos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/documentos').catch(() => window.location.reload()))
  );
const UbicacionesEquipos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/ubicaciones').catch(() => window.location.reload()))
  );
const Marcas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/marcas').catch(() => window.location.reload()))
  );
const EquipmentsCharacteristics = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/equipments-characteristics').catch(() => window.location.reload()))
  );

const CondicionesVentas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/condiciones-ventas').catch(() => window.location.reload()))
  );
const CondicionesInstalaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/condiciones-instalaciones').catch(() => window.location.reload()))
  );
const CondicionesGarantias = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/condiciones-garantias').catch(() => window.location.reload()))
  );
const AvisoPrivacidad = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/aviso-privacidad').catch(() => window.location.reload()))
  );
const ClasificadoresProveedores = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/clasificadores-proveedores').catch(() => window.location.reload()))
  );
const CondicionesContratos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/condiciones-contratos').catch(() => window.location.reload()))
  );
const FichasTecnicas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/fichas-tecnicas').catch(() => window.location.reload()))
  );

const Fabricantes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/fabricantes').catch(() => window.location.reload()))
  );
const ControlesMantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/controles-mantenimientos').catch(() => window.location.reload()))
  );
const MaquinariaHerramientas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/maquinaria-herramientas').catch(() => window.location.reload()))
  );
const Piezas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/piezas').catch(() => window.location.reload()))
  );
const TiposPiezas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-piezas').catch(() => window.location.reload()))
  );
const ServiciosTerceros = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/servicios-terceros').catch(() => window.location.reload()))
  );
const TiposMonitoreos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-monitoreos').catch(() => window.location.reload()))
  );
const CuentasMonitoreos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/cuentas-monitoreos').catch(() => window.location.reload()))
  );
const ImportarCuentasMonitoreos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/cuentas-monitoreos/Importar').catch(() => window.location.reload()))
  );

const TiposLevantamientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-levantamientos').catch(() => window.location.reload()))
  );
const TiposOrdenesServicios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-ordenes-servicios').catch(() => window.location.reload()))
  );
const ConfiguracionesCarrusel = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/configuraciones_carrusel').catch(() => window.location.reload()))
  );
const DocumentosEmpleados = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/documentos-empleados').catch(() => window.location.reload()))
  );
const DescuentosPagos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/descuentos-pagos').catch(() => window.location.reload()))
  );

const Estados = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/estados').catch(() => window.location.reload()))
  );
const Paises = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/paises').catch(() => window.location.reload()))
  );
const Colonias = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/colonias').catch(() => window.location.reload()))
  );
const ImportarColonias = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/colonias/import').catch(() => window.location.reload()))
  );

const Municipios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/municipios').catch(() => window.location.reload()))
  );
const ImportarMunicipios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/municipios/import').catch(() => window.location.reload()))
  );

const Soluciones_Solicitadas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/soluciones_solicitadas').catch(() => window.location.reload()))
  );
const Tipos_Servicios_Solicitados = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos_servicios_solicitados').catch(() => window.location.reload()))
  );
const Tipos_Mano_Obra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos_mano_obra').catch(() => window.location.reload()))
  );
const Tipos_Cobro_Equipo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos_cobro_equipo').catch(() => window.location.reload()))
  );
const Dealers = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/dealers').catch(() => window.location.reload()))
  );

const MediosComunicaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/medios-comunicaciones').catch(() => window.location.reload()))
  );

const CustomersClasifications = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/customers-clasifications').catch(() => window.location.reload()))
  );

const TiposRecibos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-recibos').catch(() => window.location.reload()))
  );

const TiposFacturas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos-facturas').catch(() => window.location.reload()))
  );

// Levantamientos

const Levantamientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/levantamientos').catch(() => window.location.reload()))
  );
const NuevoLevantamiento = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/levantamientos/Nuevo').catch(() => window.location.reload()))
  );
const EditarLevantamiento = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/levantamientos/Editar').catch(() => window.location.reload()))
  );
const GenerarCotizacionLevantamiento = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/levantamientos/GenerarCotizacion').catch(() => window.location.reload()))
  );

// Cotizaciones

const Cotizaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones').catch(() => window.location.reload()))
  );
const CotizacionesDetalle = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones/detalle').catch(() => window.location.reload()))
  );
const NuevaCotizacion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones/New').catch(() => window.location.reload()))
  );
const NuevaCotizacionRapida = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones/FastNew').catch(() => window.location.reload()))
  );
const EditarCotizacion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones/Edit').catch(() => window.location.reload()))
  );
const ConvertirCotizacion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cotizaciones/ConvertirCotizacion').catch(() => window.location.reload()))
  );

// Recibos

const Recibos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/recibos').catch(() => window.location.reload()))
  );
const RecibosDetalle = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/recibos/detalle').catch(() => window.location.reload()))
  );
const NuevoRecibo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/recibos/New').catch(() => window.location.reload()))
  );
const EditarRecibo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/recibos/Edit').catch(() => window.location.reload()))
  );

const Compras = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/compras').catch(() => window.location.reload()))
  );
const NuevaCompra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/compras/New').catch(() => window.location.reload()))
  );
const EditarCompra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/compras/Edit').catch(() => window.location.reload()))
  );
const DetalleCompra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/compras/detail').catch(() => window.location.reload()))
  );
const SolicitudesCompras = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/solicitudes-compras').catch(() => window.location.reload()))
  );
const NuevaSolicitudCompra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/solicitudes-compras/New').catch(() => window.location.reload()))
  );
const EditarSolicitudCompra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/solicitudes-compras/Edit').catch(() => window.location.reload()))
  );
const DetalleSolicitudCompra = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/solicitudes-compras/detail').catch(() => window.location.reload()))
  );
const TiposCompras = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/compras/tipos-compras').catch(() => window.location.reload()))
  );

const Preconciliaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/preconciliaciones').catch(() => window.location.reload()))
  );
const NewPreconciliacion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/preconciliaciones/New').catch(() => window.location.reload()))
  );
const DetallePreconciliacion = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/preconciliaciones/detail').catch(() => window.location.reload()))
  );
const DetalleConciliacionClientes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/preconciliaciones/detail-clientes/').catch(() => window.location.reload()))
  );
const DetalleConciliacionPendientes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/preconciliaciones/depositos-pendientes').catch(() => window.location.reload()))
  );

const Facturas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/facturas').catch(() => window.location.reload()))
  );
const DetalleFactura = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/facturas/detail').catch(() => window.location.reload()))
  );
const ImportarFactura = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/facturas/import').catch(() => window.location.reload()))
  );
const ImportarFacturaArchivo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/facturas/import-archivo').catch(() => window.location.reload()))
  );
const ImportarFacturaFaltantes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/facturas/importar-faltantes').catch(() => window.location.reload()))
  );

const NotasCredito = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/notas_creditos').catch(() => window.location.reload()))
  );
const DetalleNotaCredito = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/notas_creditos/detail').catch(() => window.location.reload()))
  );
const ImportarNotasCreditos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/notas_creditos/import').catch(() => window.location.reload()))
  );

const CarteraVencida = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/cartera-vencida').catch(() => window.location.reload()))
  );
const ImportarCarteraVencida = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/cartera-vencida/Importar').catch(() => window.location.reload()))
  );

const EstadosCuentas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/estados-cuentas').catch(() => window.location.reload()))
  );
const ImportarEstadosCuentas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/estados-cuentas/Importar').catch(() => window.location.reload()))
  );

const Asignaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/asignaciones').catch(() => window.location.reload()))
  );
const ImportarAsignaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/asignaciones/Importar').catch(() => window.location.reload()))
  );
const DetalleAsignaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/asignaciones/detalle').catch(() => window.location.reload()))
  );

const Seguimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/seguimientos').catch(() => window.location.reload()))
  );
const Seguimiento = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/seguimientos/Seguimiento').catch(() => window.location.reload()))
  );
const AgendaSeguimiento = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/agenda-seguimientos').catch(() => window.location.reload()))
  );
const AgendaVisitas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/agenda-visitas').catch(() => window.location.reload()))
  );

const Pagos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/pagos').catch(() => window.location.reload()))
  );
const DetallePago = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/pagos/detail').catch(() => window.location.reload()))
  );
const ImportarPagos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/cartera/pagos/import').catch(() => window.location.reload()))
  );

const AddProspecto = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/prospectos/add').catch(() => window.location.reload()))
  );
const EditProspecto = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/prospectos/edit').catch(() => window.location.reload()))
  );
const ListProspecto = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/prospectos/list').catch(() => window.location.reload()))
  );
const DetailProspecto = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/prospectos/detail').catch(() => window.location.reload()))
  );

const RegisterKitServicios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/kit-servicios/register').catch(() => window.location.reload()))
  );
const EditKitServicios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/kit-servicios/edit').catch(() => window.location.reload()))
  );
const KitServicios = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/kit-servicios/list/KitServicios').catch(() => window.location.reload()))
  );

const RegisterMantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/mantenimientos/mantenimiento/register').catch(() => window.location.reload()))
  );
const EditMantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/mantenimientos/mantenimiento/edit').catch(() => window.location.reload()))
  );
const Mantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/mantenimientos/mantenimiento/list/Mantenimientos').catch(() => window.location.reload()))
  );

const RegisterPlanesMantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/planes-mantenimientos/planes/register').catch(() => window.location.reload()))
  );
const EditPlanesMantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/planes-mantenimientos/planes/edit').catch(() => window.location.reload()))
  );
const PlanesMantenimientos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/planes-mantenimientos/planes/list/PlanesMantenimientos').catch(() => window.location.reload()))
  );

const TiposPedidos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/catalogs/tipos_pedidos').catch(() => window.location.reload()))
  );

const Tareas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/tareas').catch(() => window.location.reload()))
  );
const Kpis = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/kpis').catch(() => window.location.reload()))
  );
const Mensajes = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/mensajes').catch(() => window.location.reload()))
  );
const Notificaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/notificaciones').catch(() => window.location.reload()))
  );

const Departamentos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/departamentos').catch(() => window.location.reload()))
  );
const Puestos = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/puestos').catch(() => window.location.reload()))
  );
const Empleados = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/empleados').catch(() => window.location.reload()))
  );
const TiposNominas = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/tiposnominas').catch(() => window.location.reload()))
  );
const MatrizVacaciones = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/matrizvacaciones').catch(() => window.location.reload()))
  );
const GruposEmpleados = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/grupos_empleados').catch(() => window.location.reload()))
  );
const Nomina = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/nomina/nomina').catch(() => window.location.reload()))
  );

const ConfiguracionGeneral = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/configuraciones/generales').catch(() => window.location.reload()))
  );
const EstructuraCuenta = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/configuraciones/generales/estructura-cuenta').catch(() => window.location.reload()))
  );

const ActivityUser = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/application/users/ActivityUser').catch(() => window.location.reload()))
  );
const Indicadores = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/reportes/indicadores').catch(() => window.location.reload()))
  );
const HorasDeTrabajo = (props) =>
  LazyLoad(
    props,
    React.lazy(() => import('pages/reportes/workforces').catch(() => window.location.reload()))
  );

export const RoutesApp = (
  <Route element={<App />}>
    <Route path='/' element={<PublicRoute />}>
      <Route path='login' element={<Login />} />
      <Route path='firmarCotizacion/:hash' element={<FirmarCotizacion />} />
      <Route path='firmarContrato/:hash' element={<FirmarContratoEquipo />} />
    </Route>
    <Route path='/' element={<PrivateRoute />}>
      <Route index element={<Dashboard />} />
      {/* INICIO */}
      <Route path='dashboard' element={<Dashboard />} />
      <Route onEnter={requireAuth} path='tareas' element={<Tareas />} />
      <Route path='kpis' element={<Kpis />} />
      <Route onEnter={requireAuth} path='mensajes' element={<Mensajes />} />
      <Route onEnter={requireAuth} path='notificaciones' element={<Notificaciones />} />

      {/* CONFIGURACION */}
      <Route onEnter={requireAuth} path='modulos' element={<Modules />} />
      <Route onEnter={requireAuth} path='aplicacion' element={<ApplicationData />} />
      <Route onEnter={requireAuth} path='files' element={<Files />} />
      <Route onEnter={requireAuth} path='excepciones' element={<Exceptions />} />
      <Route onEnter={requireAuth} path='usuarios' element={<Users />} />
      <Route
        onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'listado-actividades')}
        path='usuarios/actividad/:listadoID'
        element={<ActivityUser />}
      />

      {/* CARTERA */}
      <Route path='conciliaciones'>
        <Route index onEnter={requireAuth} element={<Preconciliaciones />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='conciliar'
          element={<NewPreconciliacion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:conciliacionId'
          element={<DetallePreconciliacion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detallado-clientes')}
          path='detallado-clientes/:conciliacionId'
          element={<DetalleConciliacionClientes />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'pendientes')}
          path='pendientes/:conciliacionId'
          element={<DetalleConciliacionPendientes />}
        />
      </Route>
      <Route path='facturas'>
        <Route index onEnter={requireAuth} element={<Facturas />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'facturacion')}
          path='facturacion'
          element={<NewPreconciliacion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:facturaId'
          element={<DetalleFactura />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarFactura />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar-archivo'
          element={<ImportarFacturaArchivo />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar-faltantes'
          element={<ImportarFacturaFaltantes />}
        />
      </Route>
      <Route path='notas-creditos'>
        <Route index onEnter={requireAuth} element={<NotasCredito />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:notaCreditoId'
          element={<DetalleNotaCredito />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarNotasCreditos />}
        />
      </Route>
      <Route path='pagos'>
        <Route index onEnter={requireAuth} element={<Pagos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:pagoId'
          element={<DetallePago />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarPagos />}
        />
      </Route>
      <Route path='seguimientos'>
        <Route index onEnter={requireAuth} element={<Seguimientos />} />
        <Route path='editar/:seguimientoId' element={<Seguimientos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'seguimiento')}
          path='seguimiento/:clienteId'
          element={<Seguimiento />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'seguimiento-prospecto')}
          path='seguimiento-prospecto/:prospectoId'
          element={<SeguimientoProspectos />}
        />
      </Route>
      <Route onEnter={requireAuth} path='agenda-seguimientos' element={<AgendaSeguimiento />} />
      <Route onEnter={requireAuth} path='agenda-visitas' element={<AgendaVisitas />} />

      <Route path='estados-cuentas'>
        <Route index onEnter={requireAuth} element={<EstadosCuentas />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarEstadosCuentas />}
        />
      </Route>
      <Route path='cartera-vencida'>
        <Route index onEnter={requireAuth} element={<CarteraVencida />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:facturaId'
          element={<DetalleFactura />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarCarteraVencida />}
        />
      </Route>

      <Route path='asignaciones'>
        <Route index onEnter={requireAuth} element={<Asignaciones />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle-agente')}
          path='detalle/:agenteId'
          element={<DetalleAsignaciones />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarAsignaciones />}
        />
      </Route>

      <Route onEnter={requireAuth} path='motivos-visitas' element={<Motivos />} />
      <Route onEnter={requireAuth} path='resultados-visitas' element={<ResultadosVisitas />} />

      {/* CLIENTES */}
      <Route path='levantamientos'>
        <Route index onEnter={requireAuth} element={<Levantamientos />} />
        <Route exact path='nuevo' element={<NuevoLevantamiento />} />
        <Route exact path='edicion/:levantamientoId' element={<EditarLevantamiento />} />
        <Route exact path='cotizacion/:levantamientoId' element={<GenerarCotizacionLevantamiento />} />
      </Route>

      <Route path='cotizaciones'>
        <Route index onEnter={requireAuth} element={<Cotizaciones />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nuevo-rapida'
          element={<NuevaCotizacionRapida />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nuevo-rapida/:tipoCliente/:customerId'
          element={<NuevaCotizacionRapida />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nuevo/:tipoCliente/:customerId'
          element={<NuevaCotizacion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nuevo'
          element={<NuevaCotizacion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:levantamientoId'
          element={<EditarCotizacion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path=':quotationId'
          element={<CotizacionesDetalle />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='convertir/:levantamientoId'
          element={<ConvertirCotizacion />}
        />
      </Route>

      <Route path='recibos'>
        <Route index onEnter={requireAuth} element={<Recibos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nuevo/:tipoCliente/:customerId'
          element={<NuevoRecibo />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nuevo'
          element={<NuevoRecibo />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:reciboId'
          element={<EditarRecibo />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path=':quotationId'
          element={<RecibosDetalle />}
        />
      </Route>
      <Route path='clientes-registros'>
        <Route index onEnter={requireAuth} element={<ListCustomerRegistros />} />
      </Route>
      <Route path='clientes'>
        <Route index onEnter={requireAuth} element={<ListCustomer />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='registro'
          element={<AddCustomer />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='editar/:customerId'
          element={<EditCustomer />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'ubicaciones-contactos')}
          path='ubicaciones/:customerId/contactos'
          element={<Ubicaciones />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportClientes />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:customerId'
          element={<DetailCustomer />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle-contratos')}
          path='detalle/:customerId/contrato/:documentoId'
          element={<ContractByCustomer />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle-cotizacion')}
          path='detalle/:customerId/cotizacion/:quotationId'
          element={<QuotationByCustomer />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='unificar'
          element={<UnificarClientes />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path=':customerId/editar-contrato/:adjuntoId'
          element={<EditarContrato />}
        />
      </Route>

      <Route path='prospectos'>
        <Route index onEnter={requireAuth} element={<ListProspecto />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='registro'
          element={<AddProspecto />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='editar/:prospectoId'
          element={<EditProspecto />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:prospectoId'
          element={<DetailProspecto />}
        />
      </Route>

      <Route path='agenda-monitoreo'>
        <Route index onEnter={requireAuth} element={<ListAgendaMonitoreo />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='crear'
          element={<AddAgendaMonitoreo />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='editar/:agenda_monitoreo_Id'
          element={<UptAgendaMonitoreo />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarAgendaMonitoreo />}
        />
      </Route>

      <Route onEnter={requireAuth} path='domicilios' element={<Domicilios />} />
      <Route onEnter={requireAuth} path='contactos' element={<Contactos />} />
      <Route onEnter={requireAuth} path='contactos/clientes/:contactoId' element={<ContactosClientes />} />

      <Route path='clientes-saldos' element={<ClientesSaldos />} />

      <Route onEnter={requireAuth} path='ruleta-asesores' element={<RuletaAsesores />} />

      {/* TRANSACCIONES */}
      <Route onEnter={requireAuth} path='movimientos' element={<Transations />} />
      <Route onEnter={requireAuth} path='referencias' element={<References />} />

      {/* COMPRAS */}

      <Route onEnter={requireAuth} path='tipos-compras' element={<TiposCompras />} />

      <Route path='compras'>
        <Route index onEnter={requireAuth} element={<Compras />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          exact
          path='nueva'
          element={<NuevaCompra />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path=':compraId'
          element={<DetalleCompra />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:compraId'
          element={<EditarCompra />}
        />
      </Route>

      <Route path='solicitudes-compras'>
        <Route index onEnter={requireAuth} element={<SolicitudesCompras />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nueva'
          element={<NuevaSolicitudCompra />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:solicitudCompraId'
          element={<DetalleSolicitudCompra />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:solicitudCompraId'
          element={<EditarSolicitudCompra />}
        />
      </Route>

      <Route path='recepciones-compras'>
        <Route index onEnter={requireAuth} element={<RecepcionCompras />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevaRecepcion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:recepcionCompraId'
          element={<EditarRecepcion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path=':recepcionCompraId'
          element={<DetalleRecepcion />}
        />
      </Route>

      {/* ALMACEN */}

      <Route onEnter={requireAuth} path='almacen' element={<StoreEquipments />} />
      <Route onEnter={requireAuth} path='stock-carros' element={<StoreJhonnyCars />} />

      <Route path='surtido-carros'>
        <Route index onEnter={requireAuth} element={<SurtidoCarros />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'surtir')}
          path='surtir'
          element={<SurtirCarro />}
        />
      </Route>

      {/* INVENTARIO */}

      <Route onEnter={requireAuth} path='ubicaciones' element={<UbicacionesEquipos />} />
      <Route onEnter={requireAuth} path='marcas' element={<Marcas />} />
      <Route onEnter={requireAuth} path='equipments-characteristics' element={<EquipmentsCharacteristics />} />
      <Route onEnter={requireAuth} path='fichas-tecnicas' element={<FichasTecnicas />} />
      <Route onEnter={requireAuth} path='unidades-medidas' element={<UnitMeasures />} />
      <Route onEnter={requireAuth} path='listas-precios' element={<ListasPrecios />} />

      <Route onEnter={requireAuth} path='precios-equipos' element={<PreciosEquipos />} />

      <Route path='equipos'>
        <Route index onEnter={requireAuth} element={<Equipments />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NewEquipment />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportEquipment />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:equipmentId'
          element={<DetailEquipment />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:equipmentId'
          element={<EditEquipments />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/precios/:equipmentId'
          element={<EditPreciosEquipments />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='rendimientos'
          element={<RendimientosEquipment />}
        />
      </Route>

      <Route path='servicios'>
        <Route index onEnter={requireAuth} element={<Services />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NewService />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:serviceId'
          element={<DetailService />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:serviceId'
          element={<EditServices />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportServices />}
        />
      </Route>

      <Route path='kits'>
        <Route index onEnter={requireAuth} element={<Kits />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NewKits />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:kitId'
          element={<EditKits />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportKits />}
        />
      </Route>

      <Route path='kits-comerciales'>
        <Route index onEnter={requireAuth} element={<KitsComerciales />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NewKitsComerciales />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:kitId'
          element={<EditKitsComerciales />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportKitsComerciales />}
        />
      </Route>

      <Route path='recetas'>
        <Route index onEnter={requireAuth} element={<Recetas />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NewRecetas />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:recetaId'
          element={<EditRecetas />}
        />
      </Route>

      <Route path='tipos_pedidos'>
        <Route index onEnter={requireAuth} element={<TiposPedidos />} />
      </Route>

      <Route path='clasificadores-cotizaciones'>
        <Route index onEnter={requireAuth} element={<ClasificadoresCotizaciones />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ClasificadoresCotizacionesImportar />}
        />
      </Route>
      <Route path='clasificadores-levantamientos'>
        <Route index onEnter={requireAuth} element={<ClasificadoresLevantamientos />} />
      </Route>

      <Route path='configuraciones'>
        <Route index onEnter={requireAuth} element={<Configurations />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path=':configurationId'
          element={<ConfigurationDetail />}
        />
      </Route>

      <Route path='caracteristicas'>
        <Route index onEnter={requireAuth} element={<Characteristics />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nueva'
          element={<NewCharacteristics />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:characteristicsId'
          element={<DetailCharacteristics />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:characteristicsId'
          element={<EditCharacteristics />}
        />
      </Route>

      <Route path='ordenes-traspasos'>
        <Route index onEnter={requireAuth} element={<Traspasos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevoTraspaso />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:traspasoId'
          element={<DetalleTraspaso />}
        />
      </Route>

      <Route path='ordenes-servicios-visitas'>
        <Route index onEnter={requireAuth} element={<OrdenesServicioVisitas />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones')}
          path='observaciones/:visitaId'
          element={<ObservacionesOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones-conclusiones')}
          path='observaciones-conclusiones/:visitaId'
          element={<ObservacionesConclusionesOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones-comentarios')}
          path='observaciones-comentarios/:visitaId'
          element={<ObservacionesComentariosOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'calificaciones')}
          path='calificaciones/:visitaId'
          element={<CalificacionServicioOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'firma_cliente')}
          path='firma-cliente/:visitaId'
          element={<FirmaClienteServicioOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'firma_agente')}
          path='firma-agente/:visitaId'
          element={<FirmaAgenteOrdenServicio />}
        />
        {/* <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'crear')} path='nuevo' element={<NuevoOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'editar')} path='editar/:ordenServicioId' element={<EditarOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'observaciones')} path='observaciones/:ordenServicioId' element={<ObservacionesOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'calificacion_servicio')} path='calificacion_servicio/:ordenServicioId' element={<CalificacionServicioOrdenServicio/>} />
        <Route onEnter={(nextState, replace,  wrappedNext)=> requireAuth(nextState, replace,  wrappedNext, 'detalle')} path='detalle/:ordenServicioId' element={<DetalleOrdenServicio/>} /> */}
      </Route>
      <Route path='ordenes-servicio'>
        <Route index onEnter={requireAuth} element={<OrdenesServicio />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevoOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='editar/:ordenServicioId'
          element={<EditarOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'visitas')}
          path='visitas/:ordenServicioId'
          element={<VisitasOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'observaciones')}
          path='observaciones/:ordenServicioId'
          element={<ObservacionesOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'calificacion_servicio')}
          path='calificacion_servicio/:ordenServicioId'
          element={<CalificacionServicioOrdenServicio />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:ordenServicioId'
          element={<DetalleOrdenServicio />}
        />
      </Route>

      <Route path='pedidos'>
        <Route index onEnter={requireAuth} element={<Pedidos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevoPedido />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:pedidoId'
          element={<DetallePedido />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='edicion/:pedidoId'
          element={<EditarPedido />}
        />
      </Route>

      <Route path='conversiones'>
        <Route index onEnter={requireAuth} element={<Conversiones />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevoConversion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:conversionId'
          element={<DetalleConversion />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='edicion/:conversionId'
          element={<EditarConversion />}
        />
      </Route>

      <Route path='ordenes-mermas'>
        <Route index onEnter={requireAuth} element={<Mermas />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevoMerma />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:mermaId'
          element={<DetalleMerma />}
        />
      </Route>

      <Route path='ajustes'>
        <Route index onEnter={requireAuth} element={<Ajustes />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NuevoAjuste />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'detalle')}
          path='detalle/:ajusteId'
          element={<DetalleAjuste />}
        />
      </Route>

      <Route path='existencias'>
        <Route index onEnter={requireAuth} element={<Existencias />} />
      </Route>

      {/* MANTENIMIENTO */}

      <Route path='planes-mantenimientos'>
        <Route index onEnter={requireAuth} element={<PlanesMantenimientos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='captura'
          element={<RegisterPlanesMantenimientos />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:planMantenimientoId'
          element={<EditPlanesMantenimientos />}
        />
      </Route>

      <Route path='mantenimientos'>
        <Route index onEnter={requireAuth} element={<Mantenimientos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='captura'
          element={<RegisterMantenimientos />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:mantenimientoId'
          element={<EditMantenimientos />}
        />
      </Route>

      <Route path='kit-servicios'>
        <Route index onEnter={requireAuth} element={<KitServicios />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='captura'
          element={<RegisterKitServicios />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'editar')}
          path='edicion/:kitServicioId'
          element={<EditKitServicios />}
        />
      </Route>

      <Route onEnter={requireAuth} path='maquinaria-herramientas' element={<MaquinariaHerramientas />} />
      <Route onEnter={requireAuth} path='servicios-terceros' element={<ServiciosTerceros />} />
      <Route onEnter={requireAuth} path='controles-mantenimientos' element={<ControlesMantenimientos />} />
      <Route onEnter={requireAuth} path='tipos-piezas' element={<TiposPiezas />} />
      <Route onEnter={requireAuth} path='piezas' element={<Piezas />} />
      <Route onEnter={requireAuth} path='fabricantes' element={<Fabricantes />} />
      <Route onEnter={requireAuth} path='esquemas-pago' element={<EsquemasPago />} />

      {/* VENDEDORES */}

      <Route onEnter={requireAuth} path='vendedores' element={<Sellers />} />

      {/* CATALOGOS */}

      <Route path='agentes'>
        <Route index onEnter={requireAuth} element={<Agentes />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'clientes')}
          path='clientes/:agenteId'
          element={<AgentesClientes />}
        />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'prospectos')}
          path='prospectos/:agenteId'
          element={<AgentesProspectos />}
        />
      </Route>

      <Route onEnter={requireAuth} path='tipos-servicios' element={<TipoServicios />} />

      <Route onEnter={requireAuth} path='plazos' element={<Plazos />} />
      <Route onEnter={requireAuth} path='anticipos' element={<Anticipos />} />

      <Route onEnter={requireAuth} path='titulos' element={<Titulos />} />

      <Route onEnter={requireAuth} path='tipo-cambio' element={<TipoCambios />} />
      <Route onEnter={requireAuth} path='bancos' element={<Bancos />} />
      <Route onEnter={requireAuth} path='cuentas-bancarias' element={<CuentasBancarias />} />

      <Route onEnter={requireAuth} path='documentos' element={<Documentos />} />

      <Route onEnter={requireAuth} path='condiciones-ventas' element={<CondicionesVentas />} />
      <Route onEnter={requireAuth} path='condiciones-instalacion' element={<CondicionesInstalaciones />} />
      <Route onEnter={requireAuth} path='condiciones-garantia' element={<CondicionesGarantias />} />
      <Route onEnter={requireAuth} path='aviso-privacidad' element={<AvisoPrivacidad />} />
      <Route onEnter={requireAuth} path='clasificacion-proveedores' element={<ClasificadoresProveedores />} />
      <Route onEnter={requireAuth} path='condiciones-contratos' element={<CondicionesContratos />} />

      <Route onEnter={requireAuth} path='descuentos-pagos' element={<DescuentosPagos />} />
      <Route onEnter={requireAuth} path='estados' element={<Estados />} />
      <Route onEnter={requireAuth} path='paises' element={<Paises />} />
      <Route path='colonias'>
        <Route index onEnter={requireAuth} element={<Colonias />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='importar'
          element={<ImportarColonias />}
        />
      </Route>
      <Route path='municipios'>
        <Route index onEnter={requireAuth} element={<Municipios />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='importar'
          element={<ImportarMunicipios />}
        />
      </Route>

      <Route onEnter={requireAuth} path='equipos-seguridad-electronica' element={<EquiposSeguridadElectronica />} />
      <Route onEnter={requireAuth} path='condiciones-pagos-equipos' element={<CondicionesPagoEquipos />} />
      <Route onEnter={requireAuth} path='tipos-cableados' element={<TipoCableados />} />
      <Route onEnter={requireAuth} path='tipos-recibos' element={<TiposRecibos />} />
      <Route onEnter={requireAuth} path='tipos_facturas' element={<TiposFacturas />} />
      <Route onEnter={requireAuth} path='formas-pago-equipos' element={<FormaPago />} />

      <Route onEnter={requireAuth} path='condiciones-pagos-servicios' element={<CondicionesPagoServicios />} />
      <Route onEnter={requireAuth} path='tipos-monitoreos' element={<TiposMonitoreos />} />

      <Route onEnter={requireAuth} path='cuentas-monitoreos'>
        <Route index onEnter={requireAuth} element={<CuentasMonitoreos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'importar')}
          path='importar'
          element={<ImportarCuentasMonitoreos />}
        />
      </Route>

      <Route onEnter={requireAuth} path='tipos-levantamientos' element={<TiposLevantamientos />} />
      <Route onEnter={requireAuth} path='tipos-ordenes-servicios' element={<TiposOrdenesServicios />} />
      <Route onEnter={requireAuth} path='configuraciones-carrusel' element={<ConfiguracionesCarrusel />} />
      <Route onEnter={requireAuth} path='documentos-empleados' element={<DocumentosEmpleados />} />

      <Route onEnter={requireAuth} path='tipos-contactos' element={<TiposContactos />} />
      <Route onEnter={requireAuth} path='proveedores' element={<Providers />} />

      <Route onEnter={requireAuth} path='tipo-establecimientos'>
        <Route index onEnter={requireAuth} element={<TipoEstablecimientos />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'rubros')}
          path=':establecimientoId/rubros'
          element={<Rubros />}
        />
      </Route>

      <Route path='productos'>
        <Route index onEnter={requireAuth} element={<Products />} />
        <Route
          onEnter={(nextState, replace, wrappedNext) => requireAuth(nextState, replace, wrappedNext, 'crear')}
          path='nuevo'
          element={<NewProduct />}
        />
      </Route>

      <Route onEnter={requireAuth} path='almacenes' element={<StoreHouse />} />
      <Route onEnter={requireAuth} path='sucursales' element={<Sucursales />} />
      <Route onEnter={requireAuth} path='soluciones_solicitadas' element={<Soluciones_Solicitadas />} />
      <Route onEnter={requireAuth} path='tipos_servicios_solicitados' element={<Tipos_Servicios_Solicitados />} />
      <Route onEnter={requireAuth} path='tipos_mano_obra' element={<Tipos_Mano_Obra />} />
      <Route onEnter={requireAuth} path='tipos_cobro_equipo' element={<Tipos_Cobro_Equipo />} />
      <Route onEnter={requireAuth} path='dealers' element={<Dealers />} />

      <Route onEnter={requireAuth} path='medios-comunicaciones' element={<MediosComunicaciones />} />
      <Route onEnter={requireAuth} path='clientes-clasificaciones' element={<CustomersClasifications />} />

      {/* REPORTES */}
      <Route onEnter={requireAuth} path='indicadores' element={<Indicadores />} />
      <Route onEnter={requireAuth} path='reporte-horas-trabajo' element={<HorasDeTrabajo />} />

      {/* GENERALES */}

      <Route onEnter={requireAuth} path='general' element={<ConfiguracionGeneral />} />
      <Route onEnter={requireAuth} path='estructura_cuenta' element={<EstructuraCuenta />} />

      {/* NOMINA */}
      <Route onEnter={requireAuth} path='puestos' element={<Puestos />} />
      <Route onEnter={requireAuth} path='departamentos' element={<Departamentos />} />
      <Route onEnter={requireAuth} path='empleados' element={<Empleados />} />
      <Route onEnter={requireAuth} path='tiposnominas' element={<TiposNominas />} />
      <Route onEnter={requireAuth} path='nomina' element={<Nomina />} />
      <Route onEnter={requireAuth} path='grupos-empleados' element={<GruposEmpleados />} />
      <Route onEnter={requireAuth} path='matrizvacaciones' element={<MatrizVacaciones />} />

      {/* GENERALES */}
      <Route path='logout' element={<Logout />} />
      <Route path='sin-autorizacion' element={<Forbidden />} />
      <Route path='*' element={<PageNotFound />} />
    </Route>
  </Route>
);
