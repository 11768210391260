class CustomLocalStorage {
  static getItem(key, defaultValue = null) {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.error('Error al obtener el valor de localStorage', error);
      return null;
    }
  }

  static setItem(key, value) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error al guardar el valor en localStorage', error);
    }
  }

  static removeItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error('Error al eliminar el valor de localStorage', error);
    }
  }
}

export default CustomLocalStorage;
