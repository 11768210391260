import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import NotificationSystem from 'react-notification-system';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import { WS_BASE_URL } from 'utils/api';

import {
  useStoreNotification,
  useStoreAgentes,
  useStoreAlmacenes,
  useStoreSucursales,
  useStoreTiposOrdenesServicios,
  useStoreTiposPedidos,
  useStoreUsosCfdis,
  useStoreFormasPagos,
  useStoreMetodosPagos,
  useStoreClasificacionesCotizaciones,
  useStoreClasificacionesLevantamientos,
  useStoreMedidas,
  useStoreMarcas,
  useStoreTiposRelaciones,
  useStoreGrupos,
  useStoreFichasTecnicas,
  useStoreTiposDocumentos,
  useStoreTiposLevantamientos,
  useStoreClasificacionesProveedores,
  useStoreListasPrecios,
  useStoreMonedas,
  useStoreUsuarios,
  useStoreUbicaciones,
  useStoreTiposCompras,
  useStoreTiposPartidas,
  useStoreTiposContactos,
  useStoreTitulos,
  useStoreEstados,
  useStoreTiposEstablecimientos,
  useStoreBancos,
  useStoreDocumentos,
  useStoreClasificacionesClientes,
  useStorePaises,
} from '../store/catalogs.store.js';
import CustomLocalStorage from '../utils/localStorage.js';

const socket = io(WS_BASE_URL, {
  path: '/ws',
  reconnectionDelayMax: 10000,
  transports: ['websocket'],
});

export const AuthContext = createContext();
const SICOB_APP_TOKEN = 'SICOB_APP_TOKEN';
const SICOB_APP_USER = 'SICOB_APP_USER';
const SICOB_APP_AGENTE = 'SICOB_APP_AGENTE';
const SICOB_APP_TASKS = 'SICOB_APP_TASKS';
const SICOB_APP_MESSAGES = 'SICOB_APP_MESSAGES';
const SICOB_APP_NOTIFICATIONS = 'SICOB_APP_NOTIFICATIONS';
const SICOB_APP_TIPO_CAMBIO = 'SICOB_APP_TIPO_CAMBIO';

export function AuthContextProvider({ children }) {
  const notificationSystem = useRef(null);
  const { notificaciones, alertas } = useStoreNotification();
  const [headerTitle, setHeaderTitle] = useState('');
  const position = useRef({ latitude: 0, longitude: 0 });
  const [token, setToken] = useState(CustomLocalStorage.getItem(SICOB_APP_TOKEN));
  const [user, setUser] = useState(CustomLocalStorage.getItem(SICOB_APP_USER));
  const [collapsedNavbar, setCollapsedNavbar] = useState(user?.navbar_colapsed, false);
  const [agente, setAgente] = useState(CustomLocalStorage.getItem(SICOB_APP_AGENTE, []));
  const [tasks, setTasks] = useState(CustomLocalStorage.getItem(SICOB_APP_TASKS, []));
  const [messages, setMessages] = useState(CustomLocalStorage.getItem(SICOB_APP_MESSAGES, []));
  const [notifications, setNotifications] = useState(CustomLocalStorage.getItem(SICOB_APP_NOTIFICATIONS, []));
  const getSucursales = useStoreSucursales();
  const getAlmacenes = useStoreAlmacenes();
  const getAgentes = useStoreAgentes();
  const getUsosCfdis = useStoreUsosCfdis();
  const getFormasPagos = useStoreFormasPagos();
  const getMetodosPagos = useStoreMetodosPagos();
  const getClasificacionesCotizaciones = useStoreClasificacionesCotizaciones();
  const getClasificacionesLevantamientos = useStoreClasificacionesLevantamientos();
  const getMedidas = useStoreMedidas();
  const getMarcas = useStoreMarcas();
  const getTiposRelaciones = useStoreTiposRelaciones();
  const getGrupos = useStoreGrupos();
  const getFichasTecnicas = useStoreFichasTecnicas();
  const getTiposDocumentos = useStoreTiposDocumentos();
  const getTiposLevantamientos = useStoreTiposLevantamientos();
  const getClasificacionesProveedores = useStoreClasificacionesProveedores();
  const getListasPrecios = useStoreListasPrecios();
  const getMonedas = useStoreMonedas();
  const getUsuarios = useStoreUsuarios();
  const getUbicaciones = useStoreUbicaciones();
  const getTiposCompras = useStoreTiposCompras();
  const getTiposPartidas = useStoreTiposPartidas();
  const getTiposContactos = useStoreTiposContactos();
  const getTitulos = useStoreTitulos();
  const getEstados = useStoreEstados();
  const getTiposEstablecimientos = useStoreTiposEstablecimientos();
  const getBancos = useStoreBancos();
  const getDocumentos = useStoreDocumentos();
  const getClasificacionesClientes = useStoreClasificacionesClientes();
  const getPaises = useStorePaises();
  const getTiposPedidos = useStoreTiposPedidos();
  const getTiposOrdenesServicios = useStoreTiposOrdenesServicios();

  useEffect(() => {
    if (notificaciones.length > 0) {
      const latestNotification = notificaciones[notificaciones.length - 1];
      notificationSystem.current.addNotification({
        level: latestNotification.type,
        position: 'br',
        ...latestNotification,
        children: latestNotification.link && (
          <div>
            <a href={'/' + latestNotification.link} className='btn btn-outline-warning btn-sm'>
              Ver más...
            </a>
          </div>
        ),
      });
    }
    console.log('notificaciones', notificaciones);
  }, [notificaciones]);

  useEffect(() => {
    if (alertas.length > 0) {
      const latestAlert = alertas[alertas.length - 1];
      Swal.fire({
        // text: notification.message,
        title: latestAlert.message,
        icon: latestAlert.type,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [alertas]);

  const login = useCallback(function (token, user, agente) {
    getSucursales.execute();
    getAlmacenes.execute();
    getAgentes.execute();
    getUsosCfdis.execute();
    getFormasPagos.execute();
    getMetodosPagos.execute();
    getClasificacionesCotizaciones.execute();
    getClasificacionesLevantamientos.execute();
    getMedidas.execute();
    getMarcas.execute();
    getTiposRelaciones.execute();
    getGrupos.execute();
    getFichasTecnicas.execute();
    getTiposDocumentos.execute();
    getTiposLevantamientos.execute();
    getClasificacionesProveedores.execute();
    getListasPrecios.execute();
    getMonedas.execute();
    getUsuarios.execute();
    getUbicaciones.execute();
    getTiposCompras.execute();
    getTiposPartidas.execute();
    getTiposContactos.execute();
    getTitulos.execute();
    getEstados.execute();
    getTiposEstablecimientos.execute();
    getBancos.execute();
    getDocumentos.execute();
    getClasificacionesClientes.execute();
    getPaises.execute();
    getTiposPedidos.execute();
    getTiposOrdenesServicios.execute();
    CustomLocalStorage.setItem(SICOB_APP_TOKEN, token);
    setToken(token);
    CustomLocalStorage.setItem(SICOB_APP_USER, user);
    setUser(user);
    CustomLocalStorage.setItem(SICOB_APP_AGENTE, agente);
    setAgente(agente);
  }, []);

  const logout = useCallback(function () {
    setToken(null);
    setUser(null);
    CustomLocalStorage.removeItem(SICOB_APP_TOKEN);
    CustomLocalStorage.removeItem(SICOB_APP_USER);
    CustomLocalStorage.removeItem(SICOB_APP_TASKS);
    CustomLocalStorage.removeItem(SICOB_APP_MESSAGES);
    CustomLocalStorage.removeItem(SICOB_APP_NOTIFICATIONS);
    CustomLocalStorage.removeItem(SICOB_APP_TIPO_CAMBIO);
    CustomLocalStorage.removeItem(SICOB_APP_AGENTE);
  }, []);

  const establecerDatosUsuario = useCallback(function (tasks, messages, notifications) {
    CustomLocalStorage.setItem(SICOB_APP_TASKS, tasks, []);
    setTasks(tasks);
    CustomLocalStorage.setItem(SICOB_APP_MESSAGES, messages, []);
    setMessages(messages);
    CustomLocalStorage.setItem(SICOB_APP_NOTIFICATIONS, notifications, []);
    setNotifications(notifications);
  }, []);

  const openAlert = useCallback(function (notification) {
    let tipo = notification.type || 'success';
    if (notification.type == 'danger') tipo = 'error';
    Swal.fire({
      // text: notification.message,
      title: notification.message,
      icon: tipo,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
    });
  }, []);

  const notify = useCallback(function (params) {
    if (!notificationSystem.current) return setTimeout(() => notify(params), 1000);
    notificationSystem.current.addNotification({
      level: params.type,
      position: 'br',
      ...params,
      children: params.link && (
        <div>
          <a href={'/' + params.link} className='btn btn-outline-warning btn-sm'>
            Ver más...
          </a>
        </div>
      ),
    });
  }, []);

  const wsSend = useCallback(function (data) {
    if (!socket.connected) return setTimeout(wsSend.bind(this, data), 1000);
    socket.emit(data.type, data);
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('WebSocket Client Connected');
      if (token) socket.emit('LOGIN', { fullname: user.name, email: user.email, userId: user.id });
    });
    socket.on('disconnect', () => {});
    socket.on('LOGIN', (event) => {
      const data = event;
      const type = data.type || 'success';
      const title = data.title || 'Alerta';
      notify({ type, message: data.message, title });
    });
    socket.on('NOTIFICATION', (event) => {
      const data = event;
      const type = !data.type || data.type == 'NOTIFICATION' ? 'success' : data.type;
      const title = data.title || 'Alerta';
      notify({ type, message: data.message, title });
    });
  }, []);

  useEffect(() => {
    if (token) {
      navigator.geolocation.watchPosition(
        (ubicacion) => {
          const coordenadas = ubicacion.coords;
          position.current = { latitude: coordenadas.latitude, longitude: coordenadas.longitude };
        },
        () => {}
      );
    }
  }, [token]);

  const value = useMemo(
    () => ({
      login,
      logout,
      token,
      user,
      agente,
      openAlert,
      tasks,
      messages,
      notifications,
      notify,
      establecerDatosUsuario,
      collapsedNavbar,
      setCollapsedNavbar,
      wsSend,
      headerTitle,
      setHeaderTitle,
      position,
      getAgentes,
      getSucursales,
      getAlmacenes,
    }),
    [
      login,
      logout,
      token,
      user,

      agente,
      openAlert,
      tasks,
      messages,
      notifications,
      notify,
      establecerDatosUsuario,
      collapsedNavbar,
      setCollapsedNavbar,
      wsSend,
      headerTitle,
      setHeaderTitle,
      position,
      getAgentes,
      getSucursales,
      getAlmacenes,
    ]
  );
  return (
    <AuthContext.Provider value={value}>
      {children}
      <NotificationSystem ref={notificationSystem} />
    </AuthContext.Provider>
  );
}
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuthContext() {
  return useContext(AuthContext);
}
