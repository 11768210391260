import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';
import { postRequest } from 'utils/api';
import moment from 'moment';
import axios from 'axios';
import CustomLocalStorage from '../utils/localStorage';

const ErrorBoundary = ({ children, location }) => {
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);

  useEffect(() => {
    const handleError = async (error, errorInfo) => {
      const expiration = CustomLocalStorage.getItem('expiration');
      const type = CustomLocalStorage.getItem('type');
      const email = CustomLocalStorage.getItem('email');
      const name = CustomLocalStorage.getItem('name');
      const token = CustomLocalStorage.getItem('token');

      const action = location && location.pathname ? location.pathname : '';
      if (process.env.NODE_ENV === 'production') {
        axios.post(
          'https://hooks.slack.com/services/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',
          JSON.stringify({
            text: `Usuario: ${JSON.stringify(email)}
Info: ${JSON.stringify(errorInfo)}
Error: ${error ? error.toString() : error}`,
          })
        );
        await postRequest({
          url: `exceptions`,
          body: {
            action,
            exception: JSON.stringify({
              errorInfo,
              error: error ? error.toString() : error,
            }),
            session: JSON.stringify({ expiration, type, email, name, token }),
            time: moment().format(),
          },
        });
      } else {
        setError(error);
        setErrorInfo(errorInfo);
      }
    };

    const errorListener = (event) => {
      handleError(event.error, { componentStack: event.error.stack });
    };

    window.addEventListener('error', errorListener);

    return () => {
      window.removeEventListener('error', errorListener);
    };
  }, [location]);

  if (errorInfo) {
    return (
      <div className='container well'>
        <h2>
          <Icon icon='warning-sign' /> Algo salió mal.
        </h2>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {error && error.toString()}
          <br />
          {errorInfo.componentStack}
        </div>
      </div>
    );
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default ErrorBoundary;
